import jQuery from 'jquery';
import Isotope from 'isotope-layout';

const $ = jQuery;

import 'select2';

export default class ReviewSearch {
	constructor() {
		this.$searchInput = $('#search-input');
		this.$categorySelect = $('#category-select');
		this.$ageInput = $('input[name="time"]');
		this.$ratingInput = $('input[name="rating"]');

		// Mobile
		this.isMobile = false;
		this.mobileSearch = document.querySelector('a.search_mob');
		this.$mobileSearchWp = $('.search_mob_wp');
		this.categorySelect2Mobile = null;

		this.categorySelect2 = null;
		this.categorySelect2Config = {
			placeholder: 'Select Business Category',
			ajax: {
				url: `${baseURL}/categories/list`,
				dataType: 'json',
				delay: 250,
				processResults: res => ({
					results: res.map(item => {
						return {
							id: item.id,
							text: item.name,
						};
					}),
				}),
				cache: true,
			},
		};
		this.$searchBtn = $('#search-btn');

		this.resultHolder = document.querySelector('#result-holder');
		this.iso_resultHolder = new Isotope(this.resultHolder, {
			itemSelector: '.isotope-item',
			layoutMode: 'masonry',
		});

		this.$bizLoading = $('#review-loading');
		this.$bizLoadmoreHolder = $('#review-loadmore-holder');
		this.$bizLoadmoreBtn = $('#review-loadmore-btn');

		this.$categoryListHolder = $('#category-list');
		this.$categoryList = $('#category-list ul');
		this.$loadCategory = $('#load-category');
		this.$categoryLoading = $('#category-loading');
		this.businessPage = 0;
		this.categoryPage = 0;

		this.initEvents();
	}

	initEvents() {
		// const self = this;
		this.loading(true);
		window.addEventListener('load', () => {
			this.search();
			this.loadCategories();
		});
		
		// Mobile
		this.mobileSearch.addEventListener('click', () => {
			this.isMobile = true;
			this.$mobileSearchWp.slideToggle('fast', 'linear', this.initSelect2.bind(this));
			document.querySelector('#search-btn-mobile').addEventListener('click', this.search.bind(this));
		});

		this.initSelect2();
		this.$searchBtn.on('click', this.search.bind(this));
		this.$ageInput.on('change', this.search.bind(this));
		this.$categoryList.on('click', '.category-check-boxes', this.search.bind(this));
		this.$ratingInput.on('change', this.search.bind(this));
		this.$searchInput.keypress(e => {
			if (e.which === 13) this.search();
		});

		this.$bizLoadmoreBtn.on('click', () => this.search(false));

		this.$categoryLoading.show();
		this.$loadCategory.click(this.loadCategories.bind(this));
	}

	loadCategories() {
		if (!this.$categoryLoading.is(':visible')) this.$categoryLoading.show();
		axios.get('/category/list?count=companies', {
				params: {
					page: this.categoryPage,
				},
			})
			.then(res => {
				this.$categoryLoading.hide();
				res.data.data.forEach(item => {
					this.$categoryList.append(
						`<li> 
							<label class="container_check">${item.name} <small>${item.businesses_count}</small>
								<input type="checkbox" class="category-check-boxes" value="${item.id}">
								<span class="checkmark"></span>
							</label>
						</li>`
					);
				});
				this.categoryPage = res.data.current_page + 1;
				this.$categoryList.show();
			})
			.catch(res => {
				console.log(res.response);
			});
	}

	initSelect2() {
		this.categorySelect2 = this.isMobile ? $('#category-select-mobile').select2(this.categorySelect2Config) : this.$categorySelect.select2(this.categorySelect2Config);
	}

	getSearchParams() {
		const keyword = this.isMobile ? $('#search-input-mobile').val() : this.$searchInput.val();
		const category = this.isMobile ? $('#category-select-mobile').select2('val') : this.$categorySelect.select2('val');
		const categories = $('.category-check-boxes:checked')
			.map((index, el) => {
				return $(el).val();
			})
			.get();
		if (category !== null && category !== '' && !categories.includes(category))
			categories.push(category);
		const time = document.querySelector('input[name="time"]:checked').value;
		const rating = document.querySelector('input[name="rating"]:checked').value;
		return {
			keyword,
			categories,
			time,
			rating,
		};
	}

	search(clearResults = true) {
		const { keyword, categories, time, rating } = this.getSearchParams();
		this.loading(true);
		if (clearResults) {
			this.resultHolder.innerHTML = '';
			this.iso_resultHolder.remove(this.iso_resultHolder.getItemElements());
		}
		this.businessPage = clearResults ? 0 : this.businessPage;
		axios
			.get('/reviews/ajax', {
				params: {
					q: keyword,
					cats: categories,
					time,
					rating,
					page: this.businessPage,
				}
			})
			.then(res => {
				this.loading(false);
				this.showData(res.data);
				this.loadmore(res.data);
			})
			.catch(res => {
				this.loading(false);
				console.log(res);
			});
	}

	loadmore(data) {
		if (data.next_page_url === null) {
			this.$bizLoadmoreHolder.hide();
		} else {
			this.businessPage = data.current_page + 1;
			this.$bizLoadmoreHolder.show();
		}
	};

	showData(data) {
		this.render(data.data);
	}

	loading(show = true) {
		if (show) {
			if (this.$bizLoading.is(':hidden')) this.$bizLoading.show();
		} else {
			this.$bizLoading.hide();
		}
	}

	render(reviews) {
		let content = '';
		let description;
		let reviewHolder;
		reviews.forEach(review => {
			content = `
			<div class="review_listing">
				<div class="clearfix add_bottom_15 rate-head">
					<figure><img src="${baseURL}/${review.user.avatar}" alt="${review.user.full_name}"></figure>
					<div class="wap-star">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="width:30px;" width="51.988" height="50.439" viewBox="0 0 51.988 50.439">
							<defs>
								<linearGradient id="linear-gradient" x1="0.286" y1="0.856" x2="0.893" y2="0.315" gradientUnits="objectBoundingBox">
								<stop offset="0" stop-color="#00dbf3"/>
								<stop offset="1" stop-color="#248bfa"/>
								</linearGradient>
								<filter id="ic_star_24px_blue_big" x="0" y="0" width="51.988" height="50.439" filterUnits="userSpaceOnUse">
								<feOffset dy="3" input="SourceAlpha"/>
								<feGaussianBlur stdDeviation="3.5" result="blur"/>
								<feFlood flood-opacity="0.161"/>
								<feComposite operator="in" in2="blur"/>
								<feComposite in="SourceGraphic"/>
								</filter>
							</defs>
							<g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#ic_star_24px_blue_big)">
								<path id="ic_star_24px_blue_big-2" data-name="ic_star_24px_blue_big" d="M17.494,25.66l9.575,5.779L24.529,20.547l8.46-7.329-11.14-.945L17.494,2,13.14,12.273,2,13.218l8.46,7.329L7.919,31.439Z" transform="translate(8.5 5.5)" fill="url(#linear-gradient)"/>
							</g>
						</svg>
						<strong>
							${review.rating.stars}/<small>5</small> 
						</strong>
					</div>
					<div class="clearfix"></div>
					<h3>
						<strong>${review.user.name_first}</strong> 
						<samll class="text-muted">reviewed</samll> 
						<strong><a href="${baseURL}/${review.business.slug}" title="${review.business.name}">${review.business.name}</a></strong>
					</h3>
				</div>
				<h4>"${review.title}"</h4>
				<p>${review.short_content}</p>
				<ul class="clearfix">
					<li><small>Published: ${review.published_at}</small></li>
					<li><a href="${baseURL}/review/${review.id}" class="button button--success">Read Review</a></li>
				</ul>
			</div>`;

			reviewHolder = document.createElement('div');
			reviewHolder.className = 'col-lg-6 isotope-item';
			reviewHolder.innerHTML = content; 
			this.resultHolder.appendChild(reviewHolder);
			this.iso_resultHolder.appended(reviewHolder);
		});
		this.iso_resultHolder.layout();
	}
}
