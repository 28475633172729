import 'jquery.mmenu';

(($) => {
    'use strict';
    
    const $nevMenu = $("nav#menu");
    const $hamburgeIcon = $("#hamburger");
    
    const $mmenu = $nevMenu.mmenu({
            "extensions": ["pagedim-black", "theme-dark"],
            counters: true,
            keyboardNavigation: {
                enable: true,
                enhance: true
            },
            navbar: {
                title: 'MENU'
            },
            navbars: [{
                position:'bottom',
                content: [`<a href="`+ window.baseURL +`">© `+ new Date().getFullYear() +` SeekaHost<sup>TM</sup> Directory</a>`]
            }]
        }, 
        {
        // configuration
        clone: true,
        classNames: {
            fixedElements: {
                fixed: "menu_fixed",
                sticky: "sticky"
            }
        }
    });

    const $mmenuAPI = $mmenu.data("mmenu");

    $hamburgeIcon.on("click", () => {
        $mmenuAPI.open();
    });
    
    $mmenuAPI.bind("open:finish", () => {
        setTimeout(() => {
            $hamburgeIcon.addClass("is-active");
        }, 100);
    });
    $mmenuAPI.bind("close:finish", () => {
        setTimeout(() => {
            $hamburgeIcon.removeClass("is-active");
        }, 100);
    });
    
})(jQuery);
