import '../bootstrap';
import jQuery from 'jquery';

window.jQuery = jQuery;
window.$ = jQuery;

require('popper.js');
require('bootstrap');

// window.plyr = require("plyr");
// window.Cropper = require("cropperjs").default;
// window.Tagify = require("@yaireo/tagify");

// require("hideshowpassword");
// require("jquery-nice-select");
// require("rangeslider.js");
require('slick-carousel');
// require("@fancyapps/fancybox");
// require("bootstrap-fileinput");
// require("./../../../node_modules/bootstrap-fileinput/themes/fas/theme");
require("jquery-match-height");

require('./template');
