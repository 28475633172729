(($) => {
    'use strict';

    const $window = $(window);
    const $toTop = $('#toTop');

    $window.on('scroll', function () {
        if ($(this).scrollTop() != 0) {
            $toTop.fadeIn();
        } else {
            $toTop.fadeOut();
        }
    });

    $toTop.on('click', () => {
        $('body, html').animate({
            scrollTop: 0
        }, 500);
    });
})(jQuery);
