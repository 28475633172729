import AjaxResponseHandler from '../../../utils/AjaxResponseHandler';

export default class AjaxEntryResponseHandler extends AjaxResponseHandler {
	constructor(formIdOrEle) {
		super(formIdOrEle);
		this.errortoast = this.useSwalAsErrorToast();
    }

    useSwalAsToast() {
		return this.swal.mixin({
			position: 'center',
			showConfirmButton: false,
			timer: 2000,
		});
	}

	useSwalAsErrorToast() {
		return this.swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 2000,
		});
	}
	
	handleResponse(res, after = '') {
		this.setResponse(res);
		const $form = $(this.formEle);
		switch (this.res.status) {
			case 200:
				if (after !== '')
					this.clearForm();
				this.changeBtnState(false);
				this.removeErrorMsgs($form);
				this.showSuccessResponse(after);
				break;
			case 401:
				this.changeBtnState(false);
				this.errortoast.fire({
					type: 'error',
					title: Object.prototype.hasOwnProperty.call(this.res.data, 'messages') ? this.res.data.messages : 'Unknown Error...',
				});
				break;
			case 422:
				this.changeBtnState(false);
				this.showErrors();
				this.errortoast.fire({
					type: 'error',
					title: Object.prototype.hasOwnProperty.call(this.res.data, 'message') ? this.res.data.message : 'Unknown Error...',
				});
				break;
			default:
				this.changeBtnState(false);
				this.errortoast.fire({
					type: 'error',
					title: 'Something went wrong...',
				});
				break;
		}
	}
}