import 'magnific-popup';

($ => {
	const $quickEntryModal = $('.sign-in');
	const $forgetPassBtn = $('#forgot');
	const $forgetPassHolder = $('#forgot_pw');

	$quickEntryModal.magnificPopup({
		type: 'inline',
		fixedContentPos: true,
		fixedBgPos: true,
		overflowY: 'auto',
		closeBtnInside: true,
		preloader: false,
		midClick: true,
		removalDelay: 300,
		closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
		mainClass: 'my-mfp-zoom-in',
		closeOnBgClick: false,
	});

	// Forgot Password
	$forgetPassBtn.on('click', () => $forgetPassHolder.fadeToggle('fast'));
})(jQuery);
