import AjaxResponseHandler from '../../../utils/AjaxResponseHandler';

export default class ReviewForm {
	constructor(formId, url, after = '') {
		this.formId = formId;
		this.reviewForm = document.querySelector(this.formId);
		this.$reviewForm = $(this.formId);
		this.url = url;
		this.after = after;
		this.ajaxResponseHandler = new AjaxResponseHandler(this.formId);

		this.init();
    }

    init() {
		this.ajaxResponseHandler.setCustomHandlers([
			{
				key: 'stars',
				handler: (key, msg) => {
					$('.rating').append(`<div class="mt-1 text-danger custom-err-msg">${msg}</div>`);
				},
				clear: (key) => {
					$('.rating').find('.custom-err-msg').remove();
				}
			},
		]);

		if (this.reviewForm) {
			this.reviewForm.addEventListener('submit', async (e) => {
				e.preventDefault();
				await this.save();
			});
		}
	}

	async save() {
		this.ajaxResponseHandler.changeBtnState(true);
		const formData = new FormData(this.reviewForm);
		const res = await axios.post(this.url, formData).catch(res => {
            this.ajaxResponseHandler.handleResponse(res);
		});
		if (res) {
            this.ajaxResponseHandler.handleResponse(res, this.after);
        }
	}
}