import $ from 'jquery';

require('hideshowpassword');

$('input[type="password"]').each(function(index, ele) {
	$(ele).hideShowPassword({
		show: false,
		innerToggle: 'focus',
		toggle: {
			className: 'my-toggle',
		}
	});
});
