(($) => {
    'use strict';

    const $window = $(window);
    const $header = $('.header');
    $window.on('scroll', function () {
        if ($(this).scrollTop() > 1) {
            $header.addClass("sticky");
        } else {
            $header.removeClass("sticky");
        }
    });
})(jQuery);
