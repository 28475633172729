(($) => {
	const $window = $(window);
	const $collapseFilters = $('.collapse#collapseFilters');

	$window.on('load', function () {
		// const windowWidth = $(window).width();
		if ($(this).width() < 991) {
			$collapseFilters.removeClass('show');
		} else {
			$collapseFilters.addClass('show');
		}
	});
})(jQuery);
