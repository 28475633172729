import $ from 'jquery';
import AjaxResponseHandler from './AjaxResponseHandler';

export default class FormSubmission {
	constructor(formId, url, after = '') {
		this.formId = formId;
		this.url = url;
		this.after = after;
		this.ajaxResponseHandler = new AjaxResponseHandler(this.formId);

		this.init();
	}

	init() {
		const self = this;
		$(this.formId).on('submit', function(e) {
			e.preventDefault();
			self.login(this);
		});
	}

	login(form) {
		const formData = new FormData(form);
		this.ajaxResponseHandler.changeBtnState(true);
		axios
			.post(this.url, formData)
			.then(res => {
				this.ajaxResponseHandler.handleResponse(res, this.after);
			})
			.catch(res => {
				this.ajaxResponseHandler.handleResponse(res);
			});
	}
}
