import $ from 'jquery';

export default class PasswordStrengthChecker {
	constructor (passwordStrengthInfo, passward = `[name="password"]`, passwordConfirmation = `[name="password_confirmation"]`) {
		this.$password = $(passward);
		this.$passwordConfirmation = $(passwordConfirmation);
		this.$passwordStrengthInfo = $(passwordStrengthInfo);

		this.rules = {
			//Must contain 5 characters or more
			WeakPass: /(?=.{8,}).*/,
			//Must contain lower case letters and at least one digit.
			MediumPass: /^(?=\S*?[a-z])(?=\S*?[0-9])\S{8,}$/,
			//Must contain at least one upper case letter, one lower case letter and one digit.
			StrongPass: /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])\S{8,}$/,
			//Must contain at least one upper case letter, one lower case letter and one digit.
			VryStrongPass: /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{8,}$/,
		};

		this.initEvents();
	}

	initEvents() {
		const self = this;
		this.$password.on('keyup', this.passwordStrengthCheck.bind(self));
		this.$passwordConfirmation.on('keyup', this.matchPassword.bind(self));
	}

	passwordStrengthCheck() {
		const { VryStrongPass, StrongPass, MediumPass, WeakPass } = this.rules;
		if(VryStrongPass.test(this.$password.val()))
			this.showMsg('vrystrongpass', "Very Strong! (Awesome, please don't forget your pass now!)");
		else if(StrongPass.test(this.$password.val()))
			this.showMsg('strongpass', "Strong! (Enter special chars to make even stronger");
		else if(MediumPass.test(this.$password.val()))
			this.showMsg('goodpass', "Good! (Enter uppercase letter to make strong)");
		else if(WeakPass.test(this.$password.val()))
			this.showMsg('stillweakpass', "Still Weak! (Enter digits to make good password)");
		else
			this.showMsg('weakpass', "Very Weak! (Must be 8 or more chars)");
	}

	matchPassword() {
		if(this.$password.val() !== this.$passwordConfirmation.val())
			this.showMsg('weakpass', "Passwords do not match!");
		else
			this.showMsg('goodpass', "Passwords match!");
	}

	showMsg(className, msg) {
		if (!this.$passwordStrengthInfo.is(':visible')) this.$passwordStrengthInfo.show();
		this.$passwordStrengthInfo.removeClass().addClass(className).html(msg);
	}
}

