import Swal from 'sweetalert2';

export default class AjaxResponseHandler {
	constructor(formIdOrEle) {
		this.res = null;
		this.formEle = this.setForm(formIdOrEle);
		this.customHandlers = null;
		this.swal = Swal;
		this.toast = this.useSwalAsToast();
	}

	setResponse(res) {
		this.res = res.status === 200 ? res : res.response;
	}

	useSwalAsToast() {
		return this.swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 1250,
		});
	}

	setForm(formIdOrEle) {
		return typeof formIdOrEle === 'string' ? document.querySelector(formIdOrEle) : formIdOrEle;
	}

	changeBtnState(state) {
		const btn = $(this.formEle).find('button[type="submit"]');
		if (state) {
			btn.prop('disabled', state).prepend(
				`<i class="icon-spin3 animate-spin mr-1"></i>`
			);
		} else {
			btn.prop('disabled', false).find('i').remove();
		}
	}

	clearForm() {
		this.formEle.reset();
	}

	handleResponse(res, after = '') {
		this.setResponse(res);
		const $form = $(this.formEle);
		switch (this.res.status) {
			case 200:
				if (after !== '')
					this.clearForm();
				this.changeBtnState(false);
				this.removeErrorMsgs($form);
				this.showSuccessResponse(after);
				break;
			case 401:
				this.changeBtnState(false);
				this.toast.fire({
					type: 'error',
					title: Object.prototype.hasOwnProperty.call(this.res.data, 'messages') ? this.res.data.messages : 'Unknown Error...',
				});
				break;
			case 422:
				this.changeBtnState(false);
				this.showErrors();
				this.toast.fire({
					type: 'error',
					title: Object.prototype.hasOwnProperty.call(this.res.data, 'message') ? this.res.data.message : 'Unknown Error...',
				});
				break;
			default:
				this.changeBtnState(false);
				this.toast.fire({
					type: 'error',
					title: 'Something went wrong...',
				});
				break;
		}
	}

	showSuccessResponse(after) {
		this.toast.fire({
			type: 'success',
			title: this.res.data.message || 'Successfully submitted.',
			onClose: () => {
				switch (after) {
					case 'redirect':
						if (Object.prototype.hasOwnProperty.call(this.res.data, 'redirect') && this.isURL(this.res.data.redirect)) {
							window.location.href = this.res.data.redirect;
						} else {
							window.location.reload();
						}
						break;
					case 'reload':
						window.location.reload();
						break;
					default:
						break;
				}
			},
		});
	}

	isURL(redirect) {
		const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
		return pattern.test(redirect);
	}

	showErrors() {
		const $form = $(this.formEle);
		this.removeErrorMsgs($form);
		const self = this;
		$.each(this.res.data.errors, function(key, val) {
			if (self.isSetCustomHandler(key)) {
				self.customHandlers.forEach((item, index) => {
					if (item.key === key)
						item.handler(key, val.reverse().pop());
				});
			} else {
				let field = $();
				if ($form.find(`[name="${key}"]`).length > 0) {
					field = $form.find(`[name="${key}"]`);
				} else {
					const arry = key.split(/\.(?=[^\.]+$)/);
					field = $form.find(`[name="${arry[0]}[${arry[1]}]"]`);
					if (!(field.length > 0)) {
						field = $form.find(`[name="${arry[0]}[]"]:eq(${arry[1]})`);
					}
				}
				field.addClass('is-invalid').after(`<div class="invalid-feedback">${val.reverse().pop()}</div>`);
			}
		});
	}

	removeErrorMsgs($form) {
		$form.find('.invalid-feedback').remove();
		$form.find('.form-control').each(function() {
			const $this = $(this);
			if ($this.hasClass('is-invalid')) $this.removeClass('is-invalid');
		});
		if (this.customHandlers !== null) {
			this.customHandlers.forEach((item, index) => {
				if (Object.prototype.hasOwnProperty.call(item, 'clear'))
					item.clear(item.key);
			});
		}
	}

	isSetCustomHandler(key) {
		if (this.customHandlers !== null) {
			for (let i = 0; i < this.customHandlers.length; i++) {
				if (this.customHandlers[i].key === key) {
					return true;
				}
			}
		}
		return false;
	}

	setCustomHandlers(handlers) {
		if (!Array.isArray(handlers)) {
			throw new Error('Needs to be Array');
		}
		this.customHandlers = handlers;
	}
}
