import './../../../../node_modules/sticky-kit/dist/sticky-kit';

(($) => {
	'use strict';

    const $window = $(window);
    const $stickyHorizontal = $('.sticky_horizontal');
    
    $window.on('load resize', function () {
		let windowWidth = $window.width();
		if (windowWidth <= 991) {
			$stickyHorizontal.stick_in_parent({ offset_top: 40 });
		} else {
			$stickyHorizontal.stick_in_parent({ offset_top: 60 });
		}
	});	
})(jQuery);