import $ from 'jquery';
import 'magnific-popup';
import AjaxResponseHandler from '../../../utils/AjaxResponseHandler';

export default class SubscribePlan {
	constructor(stripeKey, formId) {
		this.formId = formId;
		this.$subscribeBtn = $('.subscribe');
		this.$paymentForm = $('#payment-form');
		this.ajaxResponseHandler = new AjaxResponseHandler(this.formId);
		this.stripe = window.Stripe(stripeKey);
		this.elements = this.stripe.elements();
		this.style = {
			base: {
				color: '#32325d',
				lineHeight: '18px',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		};
		this.card = null;

		this.init();
	}

	init() {
		const self = this;
		this.initPaymentPopup();
		this.card = this.elements.create('card', { style: this.style });
		this.card.mount('#card-element');

		this.card.addEventListener('change', this.handleValidationErrors);

		this.$paymentForm.on('submit', function(e) {
			e.preventDefault();
			self.stripe.createToken(self.card).then(function(result) {
				if (result.error) {
					const errorElement = document.getElementById('card-errors');
					errorElement.textContent = result.error.message;
				} else {
					self.addToken(self.$paymentForm, result.token.id);
					self.ajaxResponseHandler.changeBtnState(true);
					self.formSubmit();
				}
			});
			return false;
		});
	}

	initPaymentPopup() {
		this.$subscribeBtn.magnificPopup({
			type: 'inline',
			fixedContentPos: true,
			fixedBgPos: true,
			overflowY: 'auto',
			closeBtnInside: true,
			preloader: false,
			midClick: true,
			removalDelay: 300,
			closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
			mainClass: 'my-mfp-zoom-in',
			closeOnBgClick: false,
			callbacks: {
				open: () => {
					const mp = $.magnificPopup.instance;
					const t = $(mp.currItem.el[0]);
					const packageName = t.data('package-name');
					const paymentForm = $('#payment-form');
					paymentForm.find(`[name="package"]`).remove();
					paymentForm.append(`<input type="hidden" name="package" value="${packageName}">`);
				},
			},
		});
	}

	handleValidationErrors(ev) {
		const displayError = document.getElementById('card-errors');
		if (ev.error) {
			displayError.textContent = ev.error.message;
		} else {
			displayError.textContent = '';
		}
	}

	addToken(form, id) {
		const hiddenInput = document.createElement('input');
		hiddenInput.setAttribute('type', 'hidden');
		hiddenInput.setAttribute('name', 'stripeToken');
		hiddenInput.setAttribute('value', id);
		form.append(hiddenInput);
	}

	formSubmit() {
		const formData = new FormData(document.querySelector(this.formId));
		axios.post('/subscribe', formData)
			.then(res => {
				$.magnificPopup.close();
				this.ajaxResponseHandler.handleResponse(res, 'reload');
			})
			.catch(res => {
				this.ajaxResponseHandler.handleResponse(res);
			});
	}
}
