(($) => {
	'use strict';

    const $body = $("body");
    
    $(".btn_filt").on("click", function () {
		var el = $(this);
		el.text() == el.data("text-swap") ? el.text(el.data("text-original")) : el.text(el.data("text-swap"));
		$('html, body').animate({
			scrollTop: $body.offset().top
		}, 600);
	});
})(jQuery);