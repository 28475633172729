import ReviewForm from './inc/ReviewForm';
import ContactBusiness from './inc/ContactBusiness';
require("@fancyapps/fancybox")

if (document.querySelector('#tabs') !== null) {
	new CBPFWTabs(document.getElementById('tabs'));

	/* $('#gallery').slick({
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		dots: true,
		centerMode: true,
  		variableWidth: true,
		autoplay: true,
		focusOnSelect: true,
		adaptiveHeight: true,
		lazyLoad: 'ondemand',
	}); */

	new ReviewForm('#reviewForm', `/reviews/${window.pageData.bizSlug}`, 'reload');

	if (document.querySelector('#contact-accoount')) {
		new ContactBusiness();
	}

	window.addEventListener('load', (event) => {
		const ledge = document.querySelector('.ledge');
		setTimeout(() => ledge.classList.remove('d-none'), 2000);
	});	
}