require('./modules/accordion');
require('./modules/collapse-search-filters');
require('./modules/faq');
require('./modules/footer-collapse');
require('./modules/logged-uer-dropdown');
require('./modules/magnific-modal');
require('./modules/mobile-menu');
require('./modules/recommended-carousel');
require('./modules/scroll-to-top');
// require('./modules/search-mobile-horizontal');
require('./modules/secondory-nav-scroll');
require('./modules/show-hide-btn');
require('./modules/sticky-filters');
require('./modules/sticky-inner-nav');
require('./modules/sticky-nav');
require('./modules/sticky-sidebar');
require('./modules/tab');
require('./modules/tooltips');
require('./modules/wow-animation');
require('./modules/show-hide-password');
