(($) => {
	'use strict';

    const $stickyNav = $('.secondary_nav');
    
	$stickyNav.find('a').on('click', function (e) {
		e.preventDefault();
		let target = this.hash;
		let $target = $(target);
		$('html, body').animate({
			'scrollTop': $target.offset().top - 85
		}, 800, 'swing');
	});
	$stickyNav.find('ul li a').on('click', function () {
		$stickyNav.find('ul li a.active').removeClass('active');
		$(this).addClass('active');
	});
})(jQuery);