(($) => {
	'use strict';
	
    const $dropdownUser = $('.dropdown-user');

    $dropdownUser.hover(function () {
		$(this).find('.dropdown-menu').stop(true, true).delay(50).fadeIn(300);
	}, function () {
		$(this).find('.dropdown-menu').stop(true, true).delay(50).fadeOut(300);
	});
})(jQuery);