import $ from 'jquery';
import Login from '../../utils/login';
import Register from '../../utils/register';
import FormSubmission from '../../utils/FormSubmission';
import PasswordStrengthChecker from '../../utils/PasswordStrengthChecker';

new Login('#quick-login__user', '/login', 'reload');

new Register('#quick-register__user', '/register', 'redirect');

new FormSubmission('#quick-forget-pass__user', '/password/email');

new PasswordStrengthChecker('#pass-info', '#reg-pswd', '#reg-pswd-confirm');

$('#forgotClose').on('click', e => {
	e.preventDefault();
	$('#forgot_pw').fadeToggle('fast');
	return;
});
