import AjaxResponseHandler from '../../utils/AjaxResponseHandler';

export default class ContactUs {
    constructor() {
        this.formId = '#contactform';
        this.contactForm = document.querySelector(this.formId);

        this.ajaxResponseHandler = new AjaxResponseHandler(this.formId);

        this.initEvent();
    }

    initEvent() {
        this.contactForm.addEventListener('submit', async (e) => {
            e.preventDefault();
            await this.enquiry();
        });
    }

    async enquiry() {
        this.ajaxResponseHandler.changeBtnState(true);
        const formData = new FormData(this.contactForm);
        const res = await axios.post(`${baseURL}/contact/send`, formData).catch(res => {
            this.ajaxResponseHandler.handleResponse(res);
        });
        if (res) {
            this.ajaxResponseHandler.handleResponse(res, 'reload');
        }
    }
}

if (document.querySelector('#contactform')) {
    new ContactUs();
}
