import jQuery from 'jquery';
import AjaxEntryResponseHandler from '../front/pages/inc/AjaxEntryResponseHandler';

const $ = jQuery;

export default class Login {
	constructor(formId, url, after = '', modalId = null) {
		this.url = url;
		this.formId = formId;
		this.ajaxResponseHandler = new AjaxEntryResponseHandler(this.formId);
		this.after = after;
		this.modalId = modalId;

		this.init();
	}

	init() {
		const self = this;
		$(this.formId).on('submit', function(e) {
			e.preventDefault();
			self.login(this);
		});
	}

	login(form) {
		const formData = new FormData(form);
		this.ajaxResponseHandler.changeBtnState(true);
		axios
			.post(this.url, formData)
			.then(res => {
				if (this.modalId !== null) $(this.modalId).modal('hide');
				if ($.magnificPopup && $.magnificPopup.instance.isOpen) $.magnificPopup.close();
				this.ajaxResponseHandler.handleResponse(res, this.after);
			})
			.catch(res => {
				this.ajaxResponseHandler.handleResponse(res);
			});
	}
}
