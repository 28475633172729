import $ from 'jquery';
import 'bootstrap';

import AjaxResponseHandler from './AjaxResponseHandler';

export default class NewsLetter {
	constructor(formId) {

		this.formId = formId;
        this.form = null;
        
		this.validate = new AjaxResponseHandler(formId);

		this.init();
	}

	init() {
		const self = this;

		$(this.formId).on('submit', function(e) {
			e.preventDefault();
			self.form = this;
			self.create();
		});
	}

	getData() {
		const formData = new FormData(this.form);
		return formData;
	}

	create() {
		axios
			.post(`/newsletter/subscribe`, this.getData())
			.then(res => {
				this.validate.handleResponse(res, true);
			})
			.catch(res => {
				this.validate.handleResponse(res);
			});
	}

}

