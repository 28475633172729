import jQuery from 'jquery';

const $ = jQuery;

import 'select2';

export default class BusinessSearch {
	constructor() {
		this.$searchInput = $('#search-input');
		this.$categorySelect = $('#category-select');
		this.$ageInput = $('input[name="time"]');
		this.$ratingInput = $('input[name="rating"]');

		// Mobile
		this.isMobile = false;
		this.mobileSearch = document.querySelector('a.search_mob');
		this.$mobileSearchWp = $('.search_mob_wp');
		this.categorySelect2Mobile = null;

		this.categorySelect2 = null;
		this.categorySelect2Config = {
			placeholder: 'Select Business Category',
			ajax: {
				url: `${baseURL}/categories/list`,
				dataType: 'json',
				delay: 250,
				processResults: res => ({
					results: res.map(item => {
						return {
							id: item.id,
							text: item.name,
						};
					}),
				}),
				cache: true,
			},
		};
		this.$searchBtn = $('#search-btn');

		this.$resultHolder = $('#result-holder');
		this.$bizLoading = $('#biz-loading');
		this.$bizLoadmoreHolder = $('#biz-loadmore-holder');
		this.$bizLoadmoreBtn = $('#biz-loadmore-btn');

		this.$categoryListHolder = $('#category-list');
		this.$categoryList = $('#category-list ul');
		this.$loadCategory = $('#load-category');
		this.$categoryLoading = $('#category-loading');
		this.businessPage = 0;
		this.categoryPage = 0;

		this.initEvents();
	}

	initEvents() {
		// const self = this;
		this.loading(true);
		window.addEventListener('load', () => {
			this.search();
			this.loadCategories();
		});

		// Mobile
		this.mobileSearch.addEventListener('click', () => {
			this.isMobile = true;
			this.$mobileSearchWp.slideToggle('fast', 'linear', this.initSelect2.bind(this));
			document.querySelector('#search-btn-mobile').addEventListener('click', this.search.bind(this));
		});

		this.initSelect2();
		this.$searchBtn.on('click', this.search.bind(this));
		this.$ageInput.on('change', this.search.bind(this));
		this.$categoryList.on('click', '.category-check-boxes', this.search.bind(this));
		this.$ratingInput.on('change', this.search.bind(this));
		this.$searchInput.keypress(e => {
			if (e.which === 13) this.search();
		});

		this.$bizLoadmoreBtn.on('click', () => this.search(false));

		this.$categoryLoading.show();
		this.$loadCategory.click(this.loadCategories.bind(this));
	}

	loadCategories() {
		if (!this.$categoryLoading.is(':visible')) this.$categoryLoading.show();
		axios.get('/category/list?count=companies', {
				params: {
					page: this.categoryPage,
				},
			})
			.then(res => {
				this.$categoryLoading.hide();
				res.data.data.forEach(item => {
					this.$categoryList.append(
						`<li> 
							<label class="container_check">${item.name} <small>${item.businesses_count}</small>
								<input type="checkbox" class="category-check-boxes" value="${item.id}">
								<span class="checkmark"></span>
							</label>
						</li>`
					);
				});
				this.categoryPage = res.data.current_page + 1;
				this.$categoryList.show();
			})
			.catch(res => {
				console.log(res.response);
			});
	}

	initSelect2() {
		this.categorySelect2 = this.isMobile ? $('#category-select-mobile').select2(this.categorySelect2Config) : this.$categorySelect.select2(this.categorySelect2Config);
	}

	getSearchParams() {
		const keyword = this.isMobile ? $('#search-input-mobile').val() : this.$searchInput.val();
		const category = this.isMobile ? $('#category-select-mobile').select2('val') : this.$categorySelect.select2('val');
		const categories = $('.category-check-boxes:checked')
			.map((index, el) => {
				return $(el).val();
			})
			.get();
		if (category !== null && category !== '' && !categories.includes(category))
			categories.push(category);
		const time = document.querySelector('input[name="time"]:checked').value;
		const rating = document.querySelector('input[name="rating"]:checked').value;
		return {
			keyword,
			categories,
			time,
			rating,
		};
	}

	search(clearResults = true) {
		const { keyword, categories, time, rating } = this.getSearchParams();
		this.loading(true);
		if (clearResults) this.$resultHolder.html('');
		this.businessPage = clearResults ? 0 : this.businessPage;
		axios
			.get('/companies/ajax', {
				params: {
					q: keyword,
					cats: categories,
					time,
					rating,
					page: this.businessPage,
				}
			})
			.then(res => {
				this.loading(false);
				this.showData(res.data);
				this.loadmore(res.data);
			})
			.catch(res => {
				this.loading(false);
				console.log(res);
			});
	}

	loadmore(data) {
		if (data.next_page_url === null) {
			this.$bizLoadmoreHolder.hide();
		} else {
			this.businessPage = data.current_page + 1;
			this.$bizLoadmoreHolder.show();
		}
	};

	showData(data) {
		this.render(data.data);
	}

	loading(show = true) {
		if (show) {
			if (this.$bizLoading.is(':hidden')) this.$bizLoading.show();
		} else {
			this.$bizLoading.hide();
		}
	}

	render(businesses) {
		let content = '';
		let avgStars = 0;
		let description;
		businesses.forEach(business => {
			avgStars = parseInt(business.avg_rating);
			description = business.meta_tag ? business.meta_tag.meta_desc || '' : '';
			content = `
			<a href="${baseURL}/${business.slug}" class="company_listing isotope-item d-block company_hover col-12">
				<div class="row">
					<div class="col-md-12 align-self-center">
						<div class="company_info">
							<div class="image mr-md-4">
								<img src="${baseURL}/${business.logo}" alt="" width="160">
							</div>
							<div class="star">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="width:35px;" width="36.438" height="35.666" viewBox="0 0 36.438 35.666">
									<defs>
										<linearGradient id="linear-gradient" x1="0.286" y1="0.856" x2="0.893" y2="0.315" gradientUnits="objectBoundingBox">
										<stop offset="0" stop-color="#00dbf3"/>
										<stop offset="1" stop-color="#248bfa"/>
										</linearGradient>
										<filter id="ic_star_24px" x="0" y="0" width="36.438" height="35.666" filterUnits="userSpaceOnUse">
										<feOffset dy="3" input="SourceAlpha"/>
										<feGaussianBlur stdDeviation="3.5" result="blur"/>
										<feFlood flood-opacity="0.161"/>
										<feComposite operator="in" in2="blur"/>
										<feComposite in="SourceGraphic"/>
										</filter>
									</defs>
									<g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#ic_star_24px)">
										<path id="ic_star_24px-2" data-name="ic_star_24px" d="M9.719,13.787l4.77,2.879L13.223,11.24l4.215-3.651-5.55-.471L9.719,2,7.55,7.118,2,7.589,6.215,11.24,4.949,16.666Z" transform="translate(8.5 5.5)" fill="url(#linear-gradient)"/>
									</g>
								</svg>
								<strong style="font-size:1.1rem;">
									${avgStars}/<small>5</small> 
								</strong>
							</div>
							<h3>${business.name}</h3>
							<small class="cate_name">${business.category.name}</small><br>
							<span>
								<i class="icon_pin_alt"></i>${business.city.name}
							</span>
							<p>${description}</p>
						</div>
					</div>
				</div>
			</a>`; /* NOTE: add excerpt */
			this.$resultHolder.append(content);
		});
	}
}
