(($) => {
    const $window = $(window);
    const $innerHeader = $('.header_in.is_sticky');

    $window.on('scroll', function () {
        if ($(this).scrollTop() > 1) {
            $innerHeader.addClass("sticky");
        } else {
            $innerHeader.removeClass("sticky");
        }
    });
})(jQuery);

