($ => {
	const $window = $(window);
	const $collapseBtMobile = $('.collapse_bt_mobile');

	$window.on('load resize', function () {
		// const windowWidth = $window.width();
		if ($(this).width() < 575) {
			$collapseBtMobile.attr("data-toggle", "collapse");
			$('footer .collapse.show').removeClass('show');
			$collapseBtMobile.on('click', function () {
				$(this).find(".circle-plus").toggleClass('opened');
			})
			$collapseBtMobile.on('click', function () {
				$(this).find(".circle-plus").toggleClass('closed');
			})
		} else {
			$('footer .collapse').addClass('show');
			$("footer .collapse_bt_mobile").attr("data-toggle", "");
		}
	});
})(jQuery);
