import 'owl.carousel';

($ => {
	const $latestRevievesSlider = $('#reccomended');

	$latestRevievesSlider.owlCarousel({
		center: true,
		items: 2,
		loop: true,
		margin: 0,
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 1
			},
			767: {
				items: 2
			},
			1000: {
				items: 3
			},
			1400: {
				items: 3
			}
		}
	});
})(jQuery);
