import 'theia-sticky-sidebar';

(($) => {
    'use strict';

    const $stickyNav = $('.secondary_nav');
    
	$('#faq_cat').theiaStickySidebar({
        additionalMarginTop: 100
    });

    $('#faq_box a[href^="#"]').on('click', function () {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') 
            || location.hostname == this.hostname) {
            let target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top -195
                }, 800);
                return false;
            }
        }
    });

    $('ul#cat_nav li a').on('click', function () {
        $('ul#cat_nav li a.active').removeClass('active');
        $(this).addClass('active');
    });
})(jQuery);

